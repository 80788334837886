import queryString from 'query-string';

import {ProfileData} from '@/src/lib/store/stores/profile';

import core from './core';

export function switchBeneficiaryAccount(CSRFToken: string | undefined, uid: string) {
  return core.post(`/api/2/account/switchBA`, queryString.stringify({CSRFToken, uid}));
}

export function getProfileInfo(flow: string | null): Promise<{data: {results: ProfileData}} | any> {
  let query = '/api/2/profilewizard';
  if (flow) {
    query += '?flow=' + flow;
  }
  return core.get(query);
}

export const getTranslatorsMyTeamAvailability = (): Promise<boolean> =>
  core.get(`/api/2/is-my-team-member`).then(({data}) => !!data?.translatorIsMyTeamMember);
