import {AxiosResponse} from 'axios';

import core from './core';
import {OptionsProps} from '../components/BaseComponents/BaseSingleSelect';
import {LanguageResponse, TargetLanguagesResponse} from '../components/Wizard/WizardViewUtils';
import {FileData, IPriceQuote, WizardData} from '../lib/store/stores/wizard';
import {ApiResponse} from '../types/Wizard/types';

export const fetchPriceQuote = (data: WizardData): Promise<IPriceQuote> =>
  core.post('/bff/priceQuote', data).then((response: AxiosResponse<IPriceQuote>) => response.data);

export const fetchTopics = (): Promise<OptionsProps[]> =>
  core.get('/bff/topics').then((response: AxiosResponse<OptionsProps[]>) => response.data);

export const fetchLanguages = (): Promise<ApiResponse<LanguageResponse>> =>
  core.get('/registry/get?data=Language').then((response) => response.data);

export const fetchTargetLanguages = (slug: string): Promise<ApiResponse<TargetLanguagesResponse>> =>
  core.get(`/api/2/wizard/getavailablelanguagesBySlug?slug=${slug}`).then((response) => response.data);

export const postUploadedFile = (data: FormData): Promise<AxiosResponse<ApiResponse<FileData[]>>> =>
  core.post('/ajax/filepicker/fileUploaded', data);
