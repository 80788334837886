import {useSelector} from 'react-redux';

import {RootState} from '@/src/lib/store/store';
import {useGetConfigQuery} from '@/src/lib/store/stores/api';

const useConfig = () => {
  const {data: config} = useSelector((state: RootState) => state.config);

  const {data} = useGetConfigQuery(undefined, {
    skip: !!config?.appDomainURL,
  });

  return config || data;
};

export default useConfig;
