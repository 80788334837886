import {FC} from 'react';

import styled from 'styled-components';

interface Props {
  icon?: any;
  fill?: string;
  boxW?: number | string;
  boxH?: number | string;
  onClick?: () => void;
  className?: string;
}

const BaseIcon: FC<Props> = ({fill, icon: IconComponent, boxW = 24, boxH = 24, ...other}) => (
  <Wrapper {...other}>{IconComponent && <IconComponent fill={fill} viewBox={`0 0 ${boxH} ${boxW}`} />}</Wrapper>
);

const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: inherit;
    height: inherit;
  }
`;

export default BaseIcon;
