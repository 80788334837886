import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {getProfileInfo} from '@/src/api/profile';

export interface ProfileExpertise {
  expertOnly: boolean;
  userExpertiseList: [];
  expertiseList: [];
}

export interface ProfileData {
  sections: string[];
  isOnVacation: boolean;
  userRole: string;
  account?: any;
  languages?: any;
  expertise?: ProfileExpertise;
  billing?: any;
  uploads?: any;
  about?: any;
  time?: any;
  references?: any;
  verification?: any;
  software_services?: any;
  networks?: any;
  mailing?: any;
  apikeys?: any;
  preftrans?: any;
  translator_guidelines?: any;
  tmxFiles?: any;
  terminology?: any;
  customer_thanks?: any;
  translator_thanks?: any;
  password?: any;
  telegram?: any;
  beneficiary_account?: any;
  custom_notes?: any;
  glossary_files?: any;
  tfa_enable?: any;
  tfa_disable?: any;
  privacy_policy?: any;
}

interface ProfileState {
  currentHash: string;
  flow: string | null;
  profileData: ProfileData;
  loading: boolean;
  error: null | string;
}

const initialState: ProfileState = {
  currentHash: '#account',
  flow: null,
  profileData: {} as ProfileData,
  loading: false,
  error: null,
};

export const getProfileData = createAsyncThunk(
  'profile/getProfileData',
  async (flow: string | null, {rejectWithValue}) => {
    try {
      const result = await getProfileInfo(flow);

      return result?.data?.results;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setFlow(state, {payload}: PayloadAction<string | null>) {
      state.flow = payload;
    },
    setProfileData(state, {payload}: PayloadAction<ProfileData>) {
      state.profileData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfileData.fulfilled, (state, action: PayloadAction<any>) => {
        state.profileData = action.payload;
        state.loading = false;
      })
      .addCase(getProfileData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload || 'Error loading profile data';
      });
  },
});

export const {setFlow, setProfileData} = profileSlice.actions;
export default profileSlice.reducer;
