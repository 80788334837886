import axios from 'axios';

const baseURL = process.env.FRONTEND_API_BASE_URL;

export const instance = axios.create({baseURL});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default instance;
